<script>
import { mapActions } from 'vuex';
import { Hub } from 'aws-amplify';

export default {
  name: 'OAuthCallback',
  methods: {
    ...mapActions('auth', ['userLoggedIn']),
  },
  async mounted() {
    if (typeof this.$auth === 'undefined' || !this.$auth.oAuthFlowInProgress) {
      await this.$router.push('/auth/login-failed');
    } else {
      Hub.listen('auth', async (event) => {
        if (event.payload.event !== 'signIn') {
          return;
        }

        try {
          await this.$auth.currentUserInfo();
          await this.userLoggedIn(this.$auth);

          await this.$router.push('/dashboard');
        } catch (e) {
          console.log(e);
          await this.$router.push('/auth/login-failed');
        }
      });
    }
  },
};
</script>

<template>
  <div>
    <h1>Logging you in</h1>
    <p>Hold tight</p>
  </div>
</template>
